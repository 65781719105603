import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table, Button } from "reactstrap";
import Bold from "../common/bold";
import Text from "../common/text";
import moment from "moment";
import ChooseSubscriptionPlan from "./chooseSubscriptionPlan";

const ADMIN_GET_ALL_PRICING = gql`
    query adminGetAllRetailerSubscriptionPlans($retailerUUID: String!) {
        adminGetAllRetailerSubscriptionPlans(retailerUUID: $retailerUUID) {
            id
            status
            createdDate
            isShippingFeePassThrough
            isDeliveryFeePassThrough
            subscriptionPlan {
              title
            }
            monthlyAmount {
                value
                currencyCode
            }
            includedDonationValue {
              value
              currencyCode
            }
            individualItemPrice {
                value
                currencyCode
            }
            unboxedPrice {
                value
                currencyCode
            }
            boxPrice {
                value
                currencyCode
            }
            largeBoxPrice {
              value
              currencyCode
            }
            oversizedItemPrice{
              value
              currencyCode
            }
            palletPrice {
                value
                currencyCode
            }
            largePalletPrice {
              value
              currencyCode
            }
            shippingFee {
                value
                currencyCode
            }
            deliveryFee {
              value
              currencyCode
            }
        }
    }
`;

const Pricing = ({ retailer }) => {
  const [adminGetAllRetailerSubscriptionPlans, { loading, data }] =
    useLazyQuery(ADMIN_GET_ALL_PRICING, {
      fetchPolicy: "no-cache",
      variables: {
        retailerUUID: retailer?.uuid,
      },
    });

  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetAllRetailerSubscriptionPlans) {
        setPricings(data.adminGetAllRetailerSubscriptionPlans);
      }
    } else {
      setPricings([]);
    }
  }, [loading]);

  useEffect(() => {
    if (!retailer || !retailer?.uuid) {
      return;
    }

    adminGetAllRetailerSubscriptionPlans();
  }, [retailer]);

  const displayPrice = (p) => (p ? `$${p?.value}` : `-`);

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <Button
          color="primary"
          className="mr-2"
          onClick={() =>
            window.location.replace(
              `/pricing/edit/` + retailer?.activePricing?.uuid,
            )
          }
        >
          Edit Pricing
        </Button>

        <ChooseSubscriptionPlan retailer={retailer} />
      </div>
      {pricings.map((pricing) => (
        <Card
          key={pricing.id}
          style={{
            background: pricing.status === "active" ? "white" : "silver",
            boxShadow: "1px 4px 14px rgba(60, 72, 72, 0.4)",
          }}
        >
          <CardBody>
            <Row>
              <Col>
                <Bold>Created Date</Bold>
                <Text>
                  {moment(pricing.createdDate).format("YYYY-MM-DD HH:mm:SS")}
                </Text>
              </Col>
              <Col>
                <Bold>Plan</Bold>
                <Text>{pricing.subscriptionPlan?.title}</Text>
              </Col>
              <Col>
                <Bold>Status</Bold>
                <Text>{pricing.status}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Bold>Shipping Fee</Bold>
                <Text>
                  {pricing.isShippingFeePassThrough
                    ? "Pass through"
                    : displayPrice(pricing.shippingFee)}
                </Text>
              </Col>
              <Col>
                <Bold>Delivery Fee</Bold>
                <Text>
                  {pricing.isDeliveryFeePassThrough
                    ? "Pass through"
                    : displayPrice(pricing.deliveryFee)}
                </Text>
              </Col>
            </Row>
            <Row className="m-3">
              <Table>
                <thead>
                  <tr>
                    <th>Monthly Amount</th>
                    <td>{displayPrice(pricing.monthlyAmount)}</td>
                  </tr>
                  <tr>
                    <th>Included Donation Value</th>
                    <td>{displayPrice(pricing.includedDonationValue)}</td>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Donation Service Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Individual Item ( {`<`} 45 lbs)</th>
                    <td>{displayPrice(pricing.individualItemPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Standard Box ( {`<`} 45 lbs)</th>
                    <td>{displayPrice(pricing.boxPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Large Box ( {`>=`} 45 lbs)</th>
                    <td>{displayPrice(pricing.largeBoxPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Unboxed</th>
                    <td>{displayPrice(pricing.unboxedPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Oversized Item ( {`>`} 125 lbs) </th>
                    <td>{displayPrice(pricing.oversizedItemPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Standard Pallet (54&#34; or less)</th>
                    <td>{displayPrice(pricing.palletPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Large Pallet ( {`>`} 54 lbs)</th>
                    <td>{displayPrice(pricing.largePalletPrice)}</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </CardBody>
        </Card>
      ))}
      {!loading && pricings?.length === 0 && (
        <Card style={{ background: "red" }}>
          <CardBody>
            <h4 style={{ color: "white", fontWeight: "bold" }}>
              {"Add pricing for this retailer"}
            </h4>
            <h5>
              {"Without pricing we won't be able to invoice this retailer."}
            </h5>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Pricing;
