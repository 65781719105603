import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import ConfirmationModal from "../modals/ConfirmationModal";

const ADMIN_GENERATE_STANDARD_SHIPPING = gql`
  mutation($input: AdminGenerateLabelInput!) {
    adminGenerateStandardShippingForExistingFulfillment(input: $input) {
      uuid
    }
  }
`;

const GenerateStandardShipping = ({ fulfillmentUUID, fulfillmentType }) => {
  const [
    generateStandardShippingModalOpen,
    setGenerateStandardShippingModalOpen,
  ] = useState(false);
  const [
    adminGenerateStandardShipping,
    { loading: generateStandardShippingLoading },
  ] = useMutation(ADMIN_GENERATE_STANDARD_SHIPPING);
  const onConfirmGenerateStandardShipping = () => {
    adminGenerateStandardShipping({
      variables: {
        input: {
          fulfillmentUUID: fulfillmentUUID,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          toast.success("Updated fulfillment");
          window.location.reload();
        }, 1500);
        setGenerateStandardShippingModalOpen(false);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      {fulfillmentType == "free_shipping" && (
        <button
          className="btn btn-primary mx-2"
          onClick={() => setGenerateStandardShippingModalOpen(true)}
        >
          Generate Standard Shipping
        </button>
      )}

      <ConfirmationModal
        open={generateStandardShippingModalOpen}
        onClose={() => setGenerateStandardShippingModalOpen(false)}
        title={"Generate Standard Shipping Rate"}
        desc={
          "Are you sure you want to generate standard shipping rate for this fulfillment?"
        }
        onConfirm={onConfirmGenerateStandardShipping}
        loading={generateStandardShippingLoading}
      />
    </>
  );
};

export default GenerateStandardShipping;
