import { Col, Row, Spinner, Button, Input } from "reactstrap";
import React from "react";
import styles from "../donate/donate.module.scss";
import SearchLocationInput from "../common/SearchLocationInput";
import { formatAddress } from "src/helpers/address";

const AssignAddress = ({ address, onChange, onSubmit, loading }) => {
  const handleSetAddress2 = (e) => {
    const x = { ...address, streetAddress2: e.target.value };
    console.log(x);
    onChange(x);
  };

  return (
    <div
      style={{ padding: "10px 20px" }}
      className={styles.donateItemEdit}
    >
      <h5>
        <b>Add new address</b>
      </h5>
      <Row className="align-items-center">
        <Col
          xs="12"
          lg="6"
        >
          <SearchLocationInput
            placeholdertext={"Street Address 1"}
            required={true}
            value={formatAddress(address?.address)}
            setAddress={onChange}
          />

          <Input
            className="form-control"
            type="text"
            placeholder="Street Address 2"
            required={false}
            name="streetAddress2"
            value={address?.streetAddress2 || ""}
            onChange={handleSetAddress2}
          />
        </Col>
      </Row>
      <br />
      <Row className="align-items-center">
        <Col
          xs="12"
          lg="2"
        >
          {loading ? (
            <Spinner />
          ) : (
            <Button
              disabled={loading}
              onClick={onSubmit}
              color="primary"
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AssignAddress;
