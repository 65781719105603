import { initializeApollo } from "../../../../../helpers/apollo";
import { CREATE_PRODUCT_BUNDLE, DELETE_PRODUCT_BUNDLE } from "./query";
import { toast } from "react-toastify";

export const createProductBundle = async (
  quantity,
  product,
  bundleUUID,
  setLoading = Function.prototype,
  setProductBundles,
) => {
  setLoading(true);
  try {
    let client = initializeApollo();
    const { data } = await client.mutate({
      mutation: CREATE_PRODUCT_BUNDLE,
      variables: {
        input: {
          quantity,
          productUUID: product?.uuid,
          bundleUUID,
        },
      },
    });
    setLoading(false);
    if (data?.createProductBundle) {
      setProductBundles((prev) => [...prev, { ...data?.createProductBundle }]);
      return {
        ...data?.createProductBundle,
      };
    } else {
      toast.error("Fail to add product to bundle");
      //   toast_error("Failed to add product to bundle!");
    }
  } catch (err) {
    toast.error("Fail to add product to bundle: " + err.message);
    // Sentry.captureException(err);
    // toast_error("Something might went wrong!");
    setLoading(false);
  }
};

export const deleteProductBundle = async (
  bundleUUID,
  productUUID,
  setProductBundles,
) => {
  //   setLoading(true);
  try {
    let client = initializeApollo();
    const { data } = await client.mutate({
      mutation: DELETE_PRODUCT_BUNDLE,
      variables: {
        input: {
          bundleUUID,
          productUUID,
        },
      },
    });
    // setLoading(false);
    if (data?.deleteProductBundle === "ok") {
      setProductBundles((prev) => {
        return [...prev.filter((d) => d.product?.uuid !== productUUID)];
      });
    } else {
      toast.error("Fail to remove product from bundle.");
      //   toast_error("Failed to remove product from bundle!");
    }
  } catch (err) {
    toast.error("Fail to remove product from bundle: " + err.message);
    // Sentry.captureException(err);
    // toast_error("Something might went wrong!");
    // setLoading(false);
  }
};
