import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import ConfirmationModal from "../modals/ConfirmationModal";

const ADMIN_ORDER_DELIVERY = gql`
  mutation($input: AdminOrderDeliveryInput!) {
    adminOrderDelivery(input: $input) {
      uuid
    }
  }
`;

const OrderDelivery = ({ fulfillmentUUID, type, hours }) => {
  const [orderDeliveryModalOpen, setOrderDeliveryModalOpen] = useState(false);
  const [adminOrderDelivery, { loading: orderDeliveryLoading }] =
    useMutation(ADMIN_ORDER_DELIVERY);
  const onConfirmOrderDelivery = () => {
    adminOrderDelivery({
      variables: {
        input: {
          fulfillmentUUID: fulfillmentUUID,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          toast.success("Ordered delivery for fulfillment");
          window.location.reload();
        }, 1500);
        setOrderDeliveryModalOpen(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const showOrderDelivery = () =>
    type == `delivery` && hours && hours?.intervals?.length > 0;

  return (
    <>
      {showOrderDelivery() && (
        <button
          className="btn btn-danger mx-2"
          onClick={() => setOrderDeliveryModalOpen(true)}
        >
          Order Delivery
        </button>
      )}

      <ConfirmationModal
        open={orderDeliveryModalOpen}
        onClose={() => setOrderDeliveryModalOpen(false)}
        title={"Order Delivery"}
        desc={"Are you sure you want to order delivery for this fulfillment?"}
        onConfirm={onConfirmOrderDelivery}
        loading={orderDeliveryLoading}
      />
    </>
  );
};

export default OrderDelivery;
