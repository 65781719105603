import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Bold from "../common/bold";
import Text from "../common/text";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { List } from "react-feather";
import TransactionList from "../transactions/transaction-list";
import axios from "axios";
import Cookies from "universal-cookie";
import { LIQUID_COOKIE, LIQUID_TOKEN } from "../../constants/cookie";
import getConfigs from "../../config/base";
import { toast } from "react-toastify";
import {
  getStripeInvoiceIDLink,
  getStripePaymentIntentIDLink,
} from "src/helpers/stripe";
import { Link } from "react-router-dom";
import Notes from "../notes/notes";

const mapStatusToBadge = {
  open: "badge-secondary",
  awaiting_payment: "badge-warning",
  processing: "badge-warning",
  retry: "badge-danger",
  failed: "badge-danger",
  closed: "badge-success",
};

const mapStatusToLabel = {
  pending: "Current",
  open: "In Review",
  closed: "Paid",
};

const ADMIN_GET_INVOICE = gql`
  query($invoiceUUID: String!) {
    adminGetInvoice(invoiceUUID: $invoiceUUID) {
      uuid
      status
      startDate
      endDate
      totalItems
      stripeInvoiceURL
      stripeInvoiceID
      paymentIntentID
      total {
        value
        currencyCode
      }
      shippingFees {
        value
        currencyCode
      }
      deliveryFees {
        value
        currencyCode
      }
      donationServiceFees {
        value
        currencyCode
      }
      serviceFees {
        value
        currencyCode
      }
      notes {
        uuid
        text
        user {
          uuid
          firstName
          lastName
        }
        createdDate
      }
      retailer {
        name
        uuid
      }
      transactions {
        uuid
        description
        type
        amount {
          value
          currencyCode
        }
        match {
          uuid
          status
          nonprofit {
            uuid
            organizationName
          }
          createdDate
        }
        fulfillment {
          uuid
          status
          type
        }
        item {
          uuid
          quantity
          product {
            uuid
            slug
            title
          }
        }
        retailerSubscriptionPlan {
          id
        }
      }
    }
  }
`;

const ADMIN_MARK_INVOICE_CLOSED = gql`
  mutation adminMarkInvoiceClosed ($invoiceUUID: String!) {
    adminMarkInvoiceClosed(invoiceUUID: $invoiceUUID) {
      uuid
    }
  }
`;

const InvoiceDetails = () => {
  const { invoiceUUID } = useParams();
  const [invoice, setInvoice] = useState({});
  const [dowloadingPDF, setDownloadingPDF] = useState(false);
  const { loading, data, refetch } = useQuery(ADMIN_GET_INVOICE, {
    variables: {
      invoiceUUID: invoiceUUID,
    },
  });
  const [adminMarkInvoiceClosed, { loading: closingInvoice }] = useMutation(
    ADMIN_MARK_INVOICE_CLOSED,
  );

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetInvoice) {
        setInvoice(data.adminGetInvoice);
      } else {
        setInvoice({});
      }
    }
  }, [loading]);

  useEffect(() => {
    refetch();
  }, []);

  const downloadInvoicePDF = () => {
    const cookies = new Cookies();
    const liquid_cookie = cookies.get(LIQUID_COOKIE);

    let token = "";
    if (liquid_cookie) {
      token = liquid_cookie[LIQUID_TOKEN];
    }

    if (!token) {
      toast.error("You are not logged in");
      return;
    }
    setDownloadingPDF(true);
    const configs = getConfigs();
    axios
      .get(configs.backend + `/invoice/pdf/${invoice.uuid}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      })
      .then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `invoice-${invoice.uuid}.pdf`;
        link.click();
        setDownloadingPDF(false);
      })
      .catch((err) => {
        setDownloadingPDF(false);
        err.response.data.text().then((text) => {
          try {
            const data = JSON.parse(text);
            toast.error(data?.error || "something went wrong!");
          } catch (e) {
            toast.error("Error parsing JSON:", e);
          }
        });
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Invoice Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    className="product-title"
                    sm={"12"}
                    md={"6"}
                  >
                    <h6>{invoice.uuid}</h6>

                    <h4>
                      <Link
                        target="_blank"
                        to={`/retailers/details/${invoice?.retailer?.uuid}`}
                      >
                        {invoice?.retailer?.name}
                      </Link>
                    </h4>

                    <Text>
                      <span
                        className={`badge ${mapStatusToBadge[invoice.status]}`}
                      >
                        {mapStatusToLabel[invoice.status] || invoice.status}
                      </span>
                    </Text>
                  </Col>
                  <Col
                    sm={"12"}
                    md={"6"}
                    className="d-flex align-items-start justify-content-end"
                  >
                    <button
                      className="btn"
                      style={{ background: "lightpink" }}
                      onClick={downloadInvoicePDF}
                    >
                      {dowloadingPDF ? <Spinner /> : "Download PDF"}
                    </button>
                    {invoice?.status !== "closed" && (
                      <button
                        className="btn ml-2"
                        style={{ background: "orange" }}
                        onClick={() => {
                          adminMarkInvoiceClosed({
                            variables: {
                              invoiceUUID: invoice.uuid,
                            },
                          })
                            .then(() => window.location.reload())
                            .catch((err) => toast.error(err.message));
                        }}
                      >
                        {closingInvoice ? <Spinner /> : "Close Invoice"}
                      </button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Start Date</Bold>
                        <Text>{invoice.startDate}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>End Date</Bold>
                        <Text>{invoice.endDate}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Total Items</Bold>
                        <Text>{invoice.totalItems}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Total</Bold>
                        <Text>${invoice.total?.value}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Donation Service Fees</Bold>
                        <Text>${invoice.donationServiceFees?.value}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Shipping Fees</Bold>
                        <Text>${invoice.shippingFees?.value}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Delivery Fees</Bold>
                        <Text>${invoice.deliveryFees?.value}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Credit Card Processing Fees</Bold>
                        <Text>${invoice.serviceFees?.value}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Date Created</Bold>
                        <Text>
                          {moment(invoice.createdDate).format(
                            "DD MMM, YYYY hh:mm:SS A",
                          )}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Payment Intent ID</Bold>
                        {invoice.paymentIntentID ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getStripePaymentIntentIDLink(
                              invoice.paymentIntentID,
                            )}
                          >
                            {invoice.paymentIntentID}
                          </a>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Stripe Invoice ID</Bold>
                        {invoice.stripeInvoiceID ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getStripeInvoiceIDLink(
                              invoice.stripeInvoiceID,
                            )}
                          >
                            {invoice.stripeInvoiceID}
                          </a>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Stripe Invoice URL</Bold>
                        {invoice.stripeInvoiceURL ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={invoice.stripeInvoiceURL}
                          >
                            {invoice.stripeInvoiceURL}
                          </a>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col
                        xs="12"
                        md="6"
                      >
                        &nbsp;
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Notes
                          notes={invoice?.notes}
                          objectType={"invoice"}
                          objectUuid={invoice?.uuid}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    <Tab className="nav-link d-flex align-items-center">
                      <List className="mr-2" />
                      Transactions
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <TransactionList
                      invoiceUUID={invoice?.uuid}
                      retailerUUID={invoice?.retailer?.uuid}
                      hideRetailerSearch={true}
                      hideAddTransactions={
                        invoice?.status !== "open" &&
                        invoice?.status !== "pending"
                      }
                    />
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default InvoiceDetails;
